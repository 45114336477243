<template>
  <div
    class="answer_list"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(183,208,208,0.8)"
  >
    <div class="answer_list_title">
      <p>当前可用量表</p>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        :show-header="false"
        :cell-style="timeStyle"
      >
        <el-table-column type="index" align="center"></el-table-column>
        <el-table-column prop="measure_title" align="left">
          <template slot-scope="scope">
            <span
              @click="goAnswer(scope.row)"
              style="display: flex"
              v-if="scope.row.buff == 0"
              >{{ scope.row.measure_title }}</span
            >
            <span
              style="display: flex"
              v-else
              class="isanswer"
              @click="err(scope.row.buff)"
              >{{ scope.row.measure_title }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 是否测试知情书 - 模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="心理普测知情同意书"
      :visible.sync="statementBox"
      width="80%"
      center
    >
      <h3 style="text-align: center">
        系统接下来将对您进行相关测试。
        <br />点击‘ <span style="color: red">我同意</span>’继续。
      </h3>
      <span slot="footer" class="dialog-footer">
        <el-button @click="statementBox = false">我不同意</el-button>
        <el-button type="primary" @click="mygree">我同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>
   <script>
import {
  loadAnswerList,
  getQrmest,
  getMeasureCode,
  getScaleSort,
  jkucache,
} from "@/api/answer.js";
import { localeData } from "moment";
export default {
  data() {
    return {
      loading: true,
      statementBox: false,
      tableData: [],
      config: {},
      currentId: "",
      options: [],
      value: "",
    };
  },
  created() {
    let user_id = localStorage.getItem("user_id");
    //  如果 有 meaids 说明，是扫码进入，则获取 量表 标识 meaids ，并赋值量表
    if (this.$route.query.meaids) {
      localStorage.setItem("ByQr", JSON.stringify(this.$route.query)); // 存储通过二维码扫描的标示
      localStorage.setItem("isReg", 1); //注册标识
      // 封装请求数据
      let data = {
        measure_token: this.$route.query.meaids,
        meaidsshow: this.$route.query.meaidsshow,
        user_id,
      };
      // 发送分配量表请求，并加载列表
      getMeasureCode(data).then((res) => {
        if (res.code === 400200) {
          this.loadAnserData();
          localStorage.removeItem("ByQr");
          localStorage.removeItem("isReg");
        }
      });
      //  如果 localStorage 中 有 ByQr 说明，还没有扫码的量表 取给 用户
    } else if (localStorage.getItem("ByQr")) {
      // 获取  localStorage 存储的 ByQr
      this.$nextTick(() => {
        let byQr = JSON.parse(localStorage.getItem("ByQr"));
        let userid = localStorage.getItem("user_id");
        // 封装请求数据
        let data2 = {
          measure_token: byQr.meaids,
          meaidsshow: byQr.meaidsshow,
          user_id: userid,
        };
        // 发送分配量表请求，并加载列表
        getMeasureCode(data2).then((res) => {
          if (res.code === 400200) {
            this.loadAnserData();
            localStorage.removeItem("ByQr");
          }
        });
      });
      // 正常登录
    } else {
      this.loadAnserData();
    }
    //获取全局配置参数
    this.$nextTick(() => {
      this.config = JSON.parse(localStorage.getItem("config"));
    });
  },
  methods: {
    // 加载列表
    loadAnserData() {
      loadAnswerList().then((res) => {
        if (res.code === 400200) {
          this.tableData = res.data;
          this.loading = false;
          let arrID = "";
          this.tableData.forEach((item) => {
            if (item.buff == 0) {
              arrID += item.id + ",";
            }
          });
          let arr = JSON.stringify(this.tableData);
          localStorage.setItem("tableData", arr);
          arrID = arrID.substr(0, arrID.length - 1).split(",");
          localStorage.setItem("allMesId", JSON.stringify(arrID));
        }
      });
    },
    // 通过测评次数渲染样式
    timeStyle(row) {
      return "background:rgb(226, 239, 240);color:#262626;border-bottom: 1px solid #707070";
    },
    mygree() {
      this.$router.push(`/answer_content?id=${this.currentId}`);
    },
    // 跳转答题页面
    goAnswer(row) {
      this.config = JSON.parse(localStorage.getItem("config"));
      if (row.id) {
        this.currentId = row.id;
        // 判断全局配置,是否同意
        if (this.config.statement == 1) {
          this.statementBox = true;
        } else {
          this.$router.push(`/answer_content?id=${this.currentId}`);
        }
      } else {
        this.$router.push(`/answer_content?id=${this.currentId}`);
      }
    },
    err(buff) {
      let msg = "";
      if (buff == 1) {
        msg = "该量表测评次数已用完！";
      } else if (buff == 2) {
        msg = "还未到测评时间，无法测评！";
      } else if (buff == 3) {
        msg = "测评时间已结束，无法测评！";
      }
      this.$message({
        showClose: true,
        duration: 1500,
        message: msg,
        type: "warning",
      });
    },
  },
};
</script>

<style lang="less">
.answer_list {
  width: 50%;
  margin: 10px auto;
  border-radius: 15px;
  box-shadow: rgb(124, 124, 124) 4px 2px 15px;
  .answer_list_title {
    border-radius: 15px 15px 0px 0px;
    background: rgb(220, 239, 241);
    p {
      text-align: center;
      padding: 10px 0;
      font-size: 20px;
      font-weight: 700;
      background: linear-gradient(to right, rgb(0, 136, 125), rgb(5, 201, 184));
      -webkit-background-clip: text;
      color: transparent;
      text-shadow: white 1px 3px 9px;
    }
  }
  .el-table {
    border-radius: 0px 0px 15px 15px;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    color: black !important;
    background-color: rgb(139, 179, 177) !important;
    font-weight: 700;
    transition: all 0.25s;
  }
  .DisableSelection > .cell {
    display: none !important;
  }
  // 加载等待框
  .el-loading-mask {
    .el-loading-spinner {
      .el-icon-loading {
        color: rgb(34, 77, 85);
      }
      .el-loading-text {
        color: rgb(34, 77, 85);
        font-weight: 700;
      }
    }
  }
  .isanswer {
    text-decoration: line-through;
    color: #aaaaaa;
  }
}
@media screen and (max-width: 812px) {
  .answer_list {
    width: 90%;
  }
}
</style>